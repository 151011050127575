jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchstart", handle, { passive: false });
      } else {
        this.addEventListener("touchstart", handle, { passive: true });
      }
    }
  };
  
  jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchmove", handle, { passive: false });
      } else {
        this.addEventListener("touchmove", handle, { passive: true });
      }
    }
  };
  
  var resizedTimes = 0;
  function resizeIframe(height, id) {
    var new_height = parseInt(height);
    $('#' + id).css('height', new_height);
  }
  
  /**** Main menu ****/
  
  function checkWidth() {
    if ($(window).width() < 1199) {
      $(".navi li ul").hide();
    } else {
      $(".navi li ul").attr('style', '');
    }
  }
  
  checkWidth();
  
  $(".menu-btn").on('click', function () {
    $(".menu-btn").toggleClass("is-active");
    $(".main-navigation").toggleClass("navi-open");
    $("body").toggleClass("navi-open");
  
    if ($(".level-2-wrapper").hasClass("active-menu")) {
      $(".level-2-wrapper").removeClass("active-menu");
    }
  });
  
  $('li.level1.sublevels').on('mouseenter', function () {
    $(this).addClass("sublevel-open");
    $(".header-container").addClass("sublevel-open");
    $("body").addClass("sublevel-open");
  }).on('mouseleave', function () {
    $(".header-container").removeClass("sublevel-open");
    $("body").removeClass("sublevel-open");
    $(this).removeClass("sublevel-open");
  });
  
  $('li.level1.sublevels span').on('click', function () {
    $(this).closest('li.level1').children('.level-2-wrapper').addClass('active-menu');
  });
  
  $('.menu-back').on('click', function () {
    $(this).closest('.level-2-wrapper').removeClass("active-menu");
  });
  
  /**** Main menu end ****/
  
  /**** SplideJS start ****/
  
  if ($('.testimonials-slider').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {
      var splide = new Splide('.testimonials-slider', {
        rewind: true,
        pagination: false,
        perpage: 1,
        autoplay: true,
        speed: 1000,
      }).mount();
    });
  }
  
  /**** SplideJS end ****/